import http from "./http";
export default (bankId) => ({
  async index(filters) {
    return http
      .index("banks", filters)
      .then((json) => ({ banks: json }))
      .catch(async (e) => ({ banks: null, error: await e }));
  },
  async get() {
    return http
      .get("banks", bankId)
      .then((json) => ({ bank: json }))
      .catch(async (e) => ({ bank: null, error: await e }));
  },
  async create(data) {
    return http
      .post("banks", data)
      .then((json) => ({ bank: json }))
      .catch(async (e) => ({ bank: null, error: await e }));
  },
  async update(data) {
    return http
      .put("banks", bankId, data)
      .then((json) => ({ bank: json }))
      .catch(async (e) => ({ bank: null, error: await e }));
  },
  async delete() {
    return http
      .delete("banks", bankId)
      .then((json) => ({ bank: json }))
      .catch(async (e) => ({ bank: null, error: await e }));
  },
});
